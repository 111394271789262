//@ts-nocheck
import { useEffect, useRef } from 'react'
import ReactGA from 'react-ga4'
import momentTimezoneData from 'moment-timezone/data/meta/latest.json'
// import { toZonedTime } from 'date-fns-tz'
import { getUnixTime, startOfDay, toDate } from 'date-fns'
import { format as formatTz } from 'date-fns-tz'

export function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    // @ts-ignore
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // @ts-ignore
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
    return
  }, [delay])
}

export const customEvent = (
  eventname: string,
  customDimensions?: Record<string>,
) => {
  ReactGA.gtag('event', eventname, {
    ...customDimensions,
  })
}

export const getCountryCode = () => {
  const country = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneToCountry = {}

  Object.keys(momentTimezoneData.zones).forEach((zone) => {
    const countryAbbr =
      momentTimezoneData.countries[momentTimezoneData.zones[zone]?.countries[0]]
        ?.abbr
    if (countryAbbr) {
      timeZoneToCountry[zone] = countryAbbr
    }
  })

  return timeZoneToCountry[country]
}

export const formatInTimeZone = (
  date: string | number | Date,
  timeZone: string,
  formatStr = 'yyyy-MM-dd HH:mm:ssXXX',
) => {
  const gmtMidnightDate = startOfDay(toDate(date))
  const zonedDate = formatTz(gmtMidnightDate, formatStr, { timeZone: 'GMT' })
  return getUnixTime(zonedDate)
}
