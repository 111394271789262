import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Input,
} from '@material-tailwind/react'
import { SocialCard } from '../SocialReplies/Card'
import MonitorButton from './monitorButton'
import SearchIcon from '../../icons/search.svg'
import Selector from '../SelectInput/Select'
import DatePicker from './datePicker'
import { ViolationTable } from './violationTable'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  fetchKeywordSubCollections,
  Iincident,
} from '../../store/features/keywordSlice'
import { AdInfo } from '../../interfaces'
import { DrawerRight } from './drawer'
import { differenceInDays, fromUnixTime, startOfDay } from 'date-fns'
import { formatInTimeZone } from '../../utils/customHooks'

const MetaKeyword = () => {
  const [activeTab, setActiveTab] = React.useState('stats')
  const containerRef = useRef<HTMLDivElement>(null)

  const [country, setCountry] = useState<string>('countries')
  const [status, setStatus] = useState<string>('all')
  const [date, setDate] = useState<Date>()
  const [search, setSearch] = useState('')

  const { keywords } = useAppSelector((store) => store.keyword)
  const { currentKeywordIncidents } = useAppSelector((store) => store.keyword)
  const { currentAccount } = useAppSelector((store) => store.account)

  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const timestamp = formatInTimeZone(startOfDay(new Date()), 'Europe/Rome')

  const keywordId = params.keywordId
  const keywordSubscriptionStartDate =
    currentAccount && keywordId
      ? currentAccount?.stripeSubscriptionData[keywordId]?.current_period_start
      : null

  const keywordSubscriptionEndDate =
    currentAccount && keywordId
      ? currentAccount?.stripeSubscriptionData[keywordId]?.current_period_end
      : null

  const daysDifference =
    keywordSubscriptionStartDate &&
    differenceInDays(
      startOfDay(new Date()),
      startOfDay(fromUnixTime(keywordSubscriptionStartDate)),
    )

  const totalDays =
    keywordSubscriptionStartDate &&
    keywordSubscriptionEndDate &&
    differenceInDays(
      fromUnixTime(keywordSubscriptionEndDate as number),
      fromUnixTime(keywordSubscriptionStartDate),
    )

  const openDrawer = (id: string) => {
    navigate(location.pathname + '/' + id)
  }

  useEffect(() => {
    if (params.keywordId) {
      fetchKeywordSubCollections(params.keywordId!, dispatch)
    }
  }, [params, params.keywordId])

  const countAdsScannedLastWeek = (
    incident: Iincident[] | AdInfo[],
    isAd?: boolean,
  ) => {
    const today = new Date()
    const oneWeekAgo = new Date()
    oneWeekAgo.setDate(today.getDate() - 7)

    return incident.filter((incidentLog) => {
      if (isAd && incidentLog.updatedAt) {
        const updatedAt = incidentLog.updatedAt
        const updatedAtDate =
          updatedAt && updatedAt ? Math.floor(updatedAt * 1000) : null
        const updatedAtDateFormatted = new Date(updatedAtDate!)
        return (
          updatedAtDateFormatted &&
          updatedAtDateFormatted >= oneWeekAgo &&
          updatedAtDateFormatted <= today
        )
      } else {
        const createdAt = incidentLog.createdAt
        const createdAtDate =
          createdAt && createdAt.seconds
            ? new Date(createdAt.seconds * 1000) // Convert seconds to milliseconds
            : null
        return (
          createdAtDate && createdAtDate >= oneWeekAgo && createdAtDate <= today
        )
      }
    }).length
  }

  const onSearch = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // const value = event.target?.value?.toLowerCase()
    setSearch(event.target?.value)
  }

  const allStatuses = [
    { value: 'all', label: 'All Statuses' },
    { value: 'reported', label: 'Reported' },
    { value: 'repeated', label: 'Repeated' },
    { value: 'resolved', label: 'Resolved' },
    { value: 'identified', label: 'Identified' },
    { value: 'not an incident', label: 'Not an incident' },
  ]

  const allCountries = [
    { value: 'countries', label: 'All Countries' },
    { value: 'Middle East', label: 'Middle East' },
    { value: 'Asia Pacific', label: 'Asia Pacific' },
    { value: 'Africa', label: 'Africa' },
    { value: 'Americas and Carribeans', label: 'Americas and Carribeans' },
    { value: 'Europe', label: 'Europe' },
  ]

  const handleStatusChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const st = value || ''
    setStatus(st as string)
  }

  const handleCountryChange = async (
    value: string | undefined,
  ): Promise<void> => {
    const country = value || ''
    setCountry(country as string)
  }

  const handleChangeTab = (value: string) => {
    setActiveTab(value)
    // childRef.current?.children![0].children[0].children[1].click()
    ;(
      containerRef.current?.querySelector(
        'li[data-value="violation"]',
      ) as HTMLElement
    )?.click()
  }

  const keyword = useMemo(() => {
    return params.keywordId
      ? keywords.find((keyword) => keyword.id === params.keywordId)
      : null
  }, [params.keywordId, keywords])

  const KeywordData = [
    {
      label: 'Overview & Stats',
      value: 'stats',
      desc: () => {
        return (
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8 px-0">
            <div className="flex gap-2">
              <SocialCard
                title="ADS SCANNED"
                totalMentions={
                  keyword?.scanData && keyword?.scanData[timestamp]
                    ? keyword.scanData![timestamp]
                    : 0
                }
                lastWeek={`${
                  keyword?.scanData && keyword?.scanData[timestamp]
                    ? keyword.scanData![timestamp]
                    : 0
                }`}
              />
              <SocialCard
                title="INFRINGEMENTS FOUND"
                totalMentions={currentKeywordIncidents?.length || 0}
                lastWeek={`${countAdsScannedLastWeek(
                  currentKeywordIncidents || [],
                )} Incidents`}
              />
              <SocialCard
                title="INFRINGEMENTS RESOLVED"
                totalMentions="0"
                lastWeek="0 Resolutions"
              />
              {/* <SocialCard
                title="AUTO TAKEDOWN"
                totalMentions="Let us do it for you"
                // lastWeek='20'
              /> */}
            </div>
            <Card
              placeholder={undefined}
              className="w-full border border-lstnBlueGray-50 rounded-lg overflow-hidden my-6 py-4 px-6  h-auto"
            >
              <CardHeader
                placeholder={undefined}
                floated={false}
                className="shadow-none text-center rounded-none mt-0 mx-0 pb-4"
              >
                <Typography
                  color="blue-gray"
                  placeholder={undefined}
                  className="mb-2 text-base font-bold uppercase text-left"
                >
                  Countries Monitored
                </Typography>
              </CardHeader>
              <CardBody placeholder={undefined} className="p-0">
                <div className="ml-[3px] mb-4">
                  {/* <Typography
                    placeholder={undefined}
                    className="text-base font-medium text-blue-gray-700 mb-2"
                  >
                    Country: Worldwide
                  </Typography> */}
                  {daysDifference !== undefined ? (
                    <div className="flex gap-1">
                      {[...Array(daysDifference + 1)].map((_, index) => {
                        // Check if index is less than or equal to totalDays
                        const subscriptionDate = fromUnixTime(
                          keywordSubscriptionStartDate,
                        )
                        if (index <= totalDays) {
                          return (
                            <MonitorButton
                              key={index}
                              day={subscriptionDate.getDate() + index}
                              incident={currentKeywordIncidents}
                              handleChangeTab={handleChangeTab}
                            />
                          )
                        }
                      })}
                    </div>
                  ) : null}
                </div>
              </CardBody>
              <CardFooter placeholder={undefined} className="p-0 ">
                <div className="flex justify-between w-full">
                  <Typography
                    placeholder="Your Projects"
                    className="text-xs text-blue-gray-400 font-semibold w-[10%] text-center"
                  >
                    30 days ago
                  </Typography>
                  <div className="w-[80%] flex items-center">
                    <div className="border-t border-gray-400 w-full" />
                  </div>
                  <Typography
                    placeholder="Your Projects"
                    className="text-xs text-blue-gray-400 font-semibold w-[10%] text-center"
                  >
                    Today
                  </Typography>
                </div>
              </CardFooter>
            </Card>
          </div>
        )
      },
    },
    {
      label: 'Incidents / Violations',
      value: 'violation',
      desc: () => {
        return (
          <div className="w-[400px] sm:overflow-x-scroll md:overflow-hidden md:w-full h-auto mx-auto mt-1 pb-8">
            <div className="mt-2 flex justify-between w-full gap-2">
              {/* <div className="grid gap-2 w-full md:grid-cols-4">
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold"
                  className="w-fit"
                  options={allStatuses}
                  selectedValue={status}
                  onChange={handleStatusChange}
                  children={undefined}
                />
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold"
                  className="w-fit"
                  options={allCountries}
                  selectedValue={country}
                  onChange={handleCountryChange}
                  children={undefined}
                />
                <Example />
                <div className="relative w-80 rounded-xl">
                  <img
                    src={SearchIcon}
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                  />
                  <Input
                    type="text"
                    size="md"
                    placeholder="Search"
                    value={search}
                    className="pl-10 !border-lstnBlueGray-100 focus:!border-gray-900 rounded-xl text-blue-gray-400 placeholder:opacity-100"
                    onChange={onSearch}
                    labelProps={{
                      className: 'hidden',
                    }}
                    crossOrigin={undefined}
                  />
                </div>
              </div> */}
              <div className="grid gap-2 w-2/3 md:grid-cols-3">
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                  className="w-full"
                  options={allStatuses}
                  selectedValue={status}
                  onChange={handleStatusChange}
                  children={undefined}
                />
                <Selector
                  selectClassName="border border-blue-gray-100 rounded-lg uppercase font-semibold w-full"
                  className="w-full"
                  options={allCountries}
                  selectedValue={country}
                  onChange={handleCountryChange}
                  children={undefined}
                />
                <DatePicker date={date} setDate={setDate} />
              </div>
              <div className="relative w-1/3 ">
                <img
                  src={SearchIcon}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400"
                />
                <Input
                  type="text"
                  size="md"
                  placeholder="Search"
                  value={search}
                  className="pl-10 w-full !border-lstnBlueGray-100 focus:!border-gray-900 rounded-lg text-blue-gray-400 placeholder:opacity-100"
                  onChange={onSearch}
                  labelProps={{
                    className: 'hidden',
                  }}
                  crossOrigin={undefined}
                />
              </div>
            </div>
            <div className="my-6">
              <ViolationTable
                openDrawer={openDrawer}
                search={search}
                date={date}
                status={status}
              />
            </div>
          </div>
        )
      },
    },
    // {
    //   label: 'Settings',
    //   value: 'setting',
    //   desc: () => {
    //     return (
    //       <Typography
    //         placeholder="Your Projects"
    //         className="text-base text-blue-gray-900 font-medium py-4"
    //       >
    //         comming soon
    //       </Typography>
    //     )
    //   },
    // },
  ]
  return (
    <>
      <Tabs value={activeTab} className="py-6" ref={containerRef}>
        <TabsHeader
          placeholder={undefined}
          className="rounded-none border-b border-blue-gray-50 bg-transparent p-0"
          indicatorProps={{
            className:
              'bg-transparent border-b-2 border-well_primary-500 shadow-none rounded-none my-3',
          }}
        >
          {KeywordData.map(({ label, value }) => (
            <Tab
              placeholder={undefined}
              key={value}
              value={value}
              onClick={() => setActiveTab(value)}
              className={
                activeTab === value
                  ? 'text-gray-900 font-medium my-3 w-fit'
                  : 'w-fit  my-3'
              }
              // style={{ width: 'fit-content' }}
            >
              {label}
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody placeholder={undefined}>
          {KeywordData.map(({ value, desc }) => (
            <TabPanel key={value} value={value} className="px-0">
              {desc()}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
      <DrawerRight />
    </>
  )
}

export default MetaKeyword
