import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Card,
  Input,
  Typography,
  Button,
  CardBody,
  Chip,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from '@material-tailwind/react'
import searchIcon from '../../icons/search.svg'
import MembersDialog from './Dialog'
import { IAccount, IUser } from '../../interfaces'
import UserProfileAvatar from '../UserProfileAvatar'
import remove from '../../icons/remove.svg'
import edit from '../../icons/edit.svg'
import invite from '../../icons/invite.svg'
import {
  updateAccountMemberRole,
  removeAccountMember,
} from '../../store/features/accountSlice'
import firebase from 'firebase/compat/app'
import axios from 'axios'
import Toast from '../Toast/Toast'
import CustomTooltip from '../Tooltip/Tooltip'
import { customEvent } from '../../utils/customHooks'
import { Invite } from '../../store/features/inviteSlice'
import { database } from '../../firebaseApp'

interface IProps {
  members: (IUser | Invite)[]
  currentAccount: IAccount
  user: firebase.UserInfo
}

const MembersTable = ({
  members,
  currentAccount,
  user,
}: IProps): React.ReactNode => {
  // console.log('A-C-C-O-U-N-T M-E-M-B-E-R-S ===>>>', members)
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [addMemberOpen, setAddMemberOpen] = useState(false)
  const [editMemberOpen, setEditMemberOpen] = useState(false)
  const [removeMemberOpen, setRemoveMemberOpen] = useState(false)
  const [member, setMember] = useState<string | undefined>('')
  const [role, setRole] = useState<string | undefined>('')
  const [memberId, setMemberId] = useState<string | undefined>('')
  const [removeInviteOpen, setRemoveInviteOpen] = useState(false)
  const [inviteId, setinviteId] = useState<string | undefined>('')
  const [addLoading, setAddLoading] = useState<boolean>(false)
  const TABLE_HEAD = [
    { value: t('accountSettings.tableName'), width: '25%' },
    { value: 'Email', width: '25%' },
    { value: t('accountSettings.role'), width: '20%' },
    { value: t('accountSettings.label'), width: '15%' },
    { value: t('accountSettings.action'), width: '15%' },
  ]

  const users = members
    .filter(
      ({ name, email }) =>
        name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email?.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    .sort((a, b) => {
      const nameA = a.name?.toLowerCase() || ''
      const nameB = b.name?.toLowerCase() || ''
      return nameA.localeCompare(nameB)
    })

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(e.target.value)
  }

  const handleAddDialog = (): void => {
    setAddMemberOpen(!addMemberOpen)
  }

  const handleAdd = async (
    accountId: string,
    email: string,
    role: string,
  ): Promise<void> => {
    setAddLoading(true)
    const domain = window.location.origin
    const token = await firebase?.auth()?.currentUser?.getIdToken()
    await axios
      .post(
        `${process.env.REACT_APP_FIREBASE_API}/accounts/inviteUser`,
        {
          data: {
            accountId: accountId,
            email: email,
            domainUrl: domain,
            role: role,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Expose-Headers': 'Content-Disposition',
          },
        },
      )
      .then(() => {
        Toast({
          title: t('accountSettings.inviteSent'),
          text: t('accountSettings.inviteSentTo', { email: email }),
          variant: 'success',
          options: { autoClose: 5000 },
        })
        customEvent('collaborator_invited', {
          accountId: accountId,
          userRole: role,
          user_userId: user.uid,
        })
        setAddLoading(false)
      })
      .catch((error) => {
        console.log(error)
        Toast({
          title: t('accountSettings.inviteFailed'),
          text: t('accountSettings.inviteFailedTo', { email: email }),
          variant: 'error',
          options: { autoClose: 5000 },
        })
        setAddLoading(false)
      })
    setAddMemberOpen(false)
  }

  const handleRemoveDialog = (): void => {
    setRemoveMemberOpen(!removeMemberOpen)
  }

  const handleRemoveMember = (email?: string, id?: string): void => {
    setMemberId(id)
    setMember(email)
    handleRemoveDialog()
  }

  const handleRemoveInviteDialog = (): void => {
    setRemoveInviteOpen(!removeInviteOpen)
  }

  const handleInviteRemove = async (removeInviteId: string): Promise<void> => {
    try {
      setAddLoading(true)
      const id = removeInviteId
      database.collection('invites').doc(id).delete()
      setRemoveInviteOpen(false)
      setAddLoading(false)
    } catch (error) {
      console.log(error)
      setAddLoading(false)
    }
  }
  const handleResendInvite = useCallback(
    async (id: string | undefined, email: string | undefined) => {
      if (id) {
        Toast({
          title: t('accountSettings.sendingInvite'),
          text: t('accountSettings.pleaseWait'),
          variant: 'success',
          options: { autoClose: 5000 },
        })
        const domain = window.location.origin
        const token = await firebase?.auth()?.currentUser?.getIdToken()
        await axios
          .post(
            `${process.env.REACT_APP_FIREBASE_API}/accounts/resendInvite`,
            {
              data: {
                inviteId: id,
                domainUrl: domain,
              },
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Access-Control-Expose-Headers': 'Content-Disposition',
              },
            },
          )
          .then(() => {
            customEvent('collaborator_invited', {
              accountId: currentAccount.id,
              userRole: role,
              user_userId: user.uid,
            })
          })
          .catch((error) => {
            console.log(error)
            Toast({
              title: t('accountSettings.inviteFailed'),
              text: t('accountSettings.inviteFailedTo', { email: email }),
              variant: 'error',
              options: { autoClose: 5000 },
            })
          })
      }
    },
    [currentAccount],
  )
  const handleRemove = async (
    accountId: string,
    memberId: string,
  ): Promise<void> => {
    setAddLoading(true)
    await removeAccountMember(accountId, memberId)
    handleRemoveDialog()
    customEvent('collaborator_removed', {
      accountId: accountId,
      user_userId: memberId,
    })
    setAddLoading(false)
  }

  const handleEditDialog = (): void => {
    setEditMemberOpen(!editMemberOpen)
  }

  const handleEditRole = (
    email?: string,
    memberRole?: string,
    id?: string,
  ): void => {
    setMemberId(id)
    setMember(email)
    setRole(memberRole)
    handleEditDialog()
  }

  const handleEdit = async (
    accountId: string,
    memberId: string,
    role: string,
    memberRole: string,
  ): Promise<void> => {
    setAddLoading(true)
    await updateAccountMemberRole(accountId, memberId, role)
    handleEditDialog()
    customEvent('collaborator_role_edited', {
      accountId: accountId,
      user_userId: memberId,
      userole: role,
      actionSource: `from-role-${memberRole}`,
    })
    setAddLoading(false)
  }

  return (
    <>
      <div className="flex items-center justify-between flex-row mb-7">
        <div className="w-72">
          <Input
            crossOrigin={undefined}
            label={t('accountSettings.search')}
            icon={<img src={searchIcon} className="h-5 w-5" />}
            onChange={handleSearchChange}
          />
        </div>
        <div className="w-max">
          <Button placeholder={undefined} size="md" onClick={handleAddDialog}>
            {t('accountSettings.addMember')}
          </Button>
        </div>
      </div>
      <Card
        placeholder={undefined}
        className="h-full w-full overflow-hidden border border-blue-gray-50"
      >
        <CardBody placeholder={undefined} className="p-0">
          <table className="w-full table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((value) => (
                  <th
                    key={value.value}
                    className="border-b border-blue-gray-100 bg-[#F9FAFB] h-10 p-4"
                    style={{
                      width: value.width,
                    }}
                  >
                    <Typography
                      placeholder={undefined}
                      variant="small"
                      color="blue-gray"
                      className="font-medium text-lstnBlueGray-900"
                    >
                      {value.value}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map(
                ({ profilePicture, name, email, id, status, role }, index) => {
                  const isLast = index === members.length - 1
                  const classes = isLast
                    ? 'p-4'
                    : 'p-4 border-b border-blue-gray-50'

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <UserProfileAvatar
                            userName={name ? name : 'NA'}
                            avatarUrl={profilePicture}
                            height="35px"
                            width="35px"
                          />
                          <div className="flex flex-col">
                            <Typography
                              placeholder={undefined}
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {name ? name : 'n/a'}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex flex-col">
                          <Typography
                            placeholder={undefined}
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {email}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <Typography
                          placeholder={undefined}
                          variant="small"
                          color="blue-gray"
                          className="font-normal capitalize"
                        >
                          {currentAccount.users[id!]?.role
                            ? currentAccount.users[id!]?.role
                            : role}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div className="w-max">
                          <Chip
                            variant="ghost"
                            size="sm"
                            value={
                              currentAccount.users[id!]?.status
                                ? currentAccount.users[id!]?.status
                                : status
                            }
                            className={
                              currentAccount.users[id!]?.status === 'active'
                                ? 'bg-lstnGreen-100 text-lstnGreen-500'
                                : 'bg-cyan-50 text-cyan-500'
                            }
                            icon={
                              <div
                                className={`w-2 h-2 rounded-full absolute bottom-1 right-1 top-1 left-1 ${
                                  currentAccount.users[id!]?.status === 'active'
                                    ? 'bg-green'
                                    : 'bg-cyan-500'
                                }`}
                              ></div>
                            }
                          />
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex justify-between items-center">
                          {currentAccount.users[id!]?.role === 'owner' ? (
                            <div className="p-2 min-w-0 h-auto">
                              <CustomTooltip
                                content={t('accountSettings.noEdit')}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-more-horizontal"
                                >
                                  <circle cx="7" cy="12" r="1"></circle>
                                  <circle cx="12" cy="12" r="1"></circle>
                                  <circle cx="17" cy="12" r="1"></circle>
                                </svg>
                              </CustomTooltip>
                            </div>
                          ) : (
                            <Menu placement="bottom-start" allowHover={true}>
                              <MenuHandler>
                                <Button
                                  placeholder={undefined}
                                  variant="text"
                                  className="p-2 min-w-0 h-auto"
                                  disabled={
                                    currentAccount.users[id!]?.role === 'owner'
                                      ? true
                                      : false
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-more-horizontal"
                                  >
                                    <circle cx="7" cy="12" r="1"></circle>
                                    <circle cx="12" cy="12" r="1"></circle>
                                    <circle cx="17" cy="12" r="1"></circle>
                                  </svg>
                                </Button>
                              </MenuHandler>
                              <MenuList
                                placeholder={undefined}
                                className="w-auto"
                              >
                                {currentAccount.users[id!]?.status ===
                                'active' ? (
                                  <MenuItem
                                    placeholder={undefined}
                                    onClick={() =>
                                      handleEditRole(
                                        email,
                                        currentAccount.users[id!]?.role || '',
                                        id,
                                      )
                                    }
                                  >
                                    <Typography
                                      placeholder="Edit"
                                      className="text-lstnBlueGray-500 font-medium text-sm flex"
                                    >
                                      <img
                                        src={edit}
                                        alt="edit"
                                        className="text-lstnBlueGray-500 pr-2"
                                        color="gray"
                                      />
                                      {t('accountSettings.edit')}
                                    </Typography>
                                  </MenuItem>
                                ) : currentAccount.users[id!]?.status ===
                                    'pending' || status === 'pending' ? (
                                  <MenuItem
                                    placeholder={undefined}
                                    onClick={() =>
                                      handleResendInvite(id, email)
                                    }
                                  >
                                    <Typography
                                      placeholder="resendInvite"
                                      className="text-lstnBlueGray-500 font-medium text-sm flex"
                                    >
                                      <img
                                        src={invite}
                                        alt="invite"
                                        className="text-lstnBlueGray-500 pr-2"
                                        color="gray"
                                      />
                                      {t('accountSettings.resendInvite')}
                                    </Typography>
                                  </MenuItem>
                                ) : null}
                                {currentAccount.users[id!]?.role !==
                                  'owner' && (
                                  <MenuItem
                                    placeholder={undefined}
                                    onClick={() => {
                                      if (status === 'pending') {
                                        handleRemoveInviteDialog()
                                        setinviteId(id)
                                      } else {
                                        handleRemoveMember(email, id)
                                      }
                                    }}
                                  >
                                    <Typography
                                      placeholder="Remove"
                                      className="text-lstnBlueGray-500 font-medium text-sm flex"
                                    >
                                      <img
                                        src={remove}
                                        alt="remove"
                                        className="text-lstnBlueGray-500 pr-2"
                                        color="gray"
                                      />
                                      {t('accountSettings.remove')}
                                    </Typography>
                                  </MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          )}
                        </div>
                      </td>
                    </tr>
                  )
                },
              )}
            </tbody>
          </table>
        </CardBody>
      </Card>
      <MembersDialog
        open={addMemberOpen}
        handleOpen={handleAddDialog}
        dialogType="add"
        currentAccountId={currentAccount.id}
        handleAdd={handleAdd}
        addLoading={addLoading}
      />
      <MembersDialog
        open={editMemberOpen}
        handleOpen={handleEditDialog}
        dialogType="edit"
        currentAccountId={currentAccount.id}
        email={member}
        memberRole={role}
        memberId={memberId}
        handleEdit={handleEdit}
        addLoading={addLoading}
      />
      <MembersDialog
        open={removeMemberOpen}
        handleOpen={handleRemoveDialog}
        dialogType="remove"
        currentAccountId={currentAccount.id}
        email={member}
        memberId={memberId}
        handleRemove={handleRemove}
        addLoading={addLoading}
      />
      <MembersDialog
        open={removeInviteOpen}
        handleOpen={handleRemoveInviteDialog}
        dialogType="inviteremove"
        handleInviteRemove={handleInviteRemove}
        removeInviteId={inviteId}
        currentAccountId={currentAccount.id}
        addLoading={addLoading}
      />
    </>
  )
}

export default MembersTable
