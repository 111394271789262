import { Typography } from '@material-tailwind/react'
import React, {
  forwardRef,
  // useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import ProjectCard from '../components/Projects/ProjectCard'
import Button from '../components/Button/TailwindButton'
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from '@material-tailwind/react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import keywordEdit from '../icons/keywordEdit.svg'
import MetaKeyword from '../components/Keyword'
import '../css/Projects.css'
import { useAppSelector } from '../store/store'
import UploadIcon from '../icons/Upload.svg'
import Toast from '../components/Toast/Toast'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import EditKeyword from '../components/Keyword/EditKeyword'
import StripeForm from '../components/Stripe'

type Selection = {
  [region: string]: {
    isFullySelected: boolean
    countries: string[]
  }
}

const KeywordPlaceholder = ({
  handleKeywordDialog,
}: {
  handleKeywordDialog: () => void
}) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center h-full">
      <img src={UploadIcon} alt="Icon" className="mb-8" />
      <Typography
        className="text-2xl font-medium mb-3"
        placeholder="Your Projects"
      >
        {/* {t('projects.getStarted')} */}
        Monitor trademark violations
      </Typography>
      <Typography
        className="text-base font-normal text-lstnBlueGray-500 text-center mb-5"
        placeholder="Your Projects"
      >
        {/* {t('projects.description')} */}
        Get Instant Alerts for Google Ads Trademark Violations and Protect Your
        Ad Spend. Protect your brand, receive 24/7 alerts, and prevent
        competitors from damaging your ad campaigns before it impacts your ROAS.
      </Typography>
      <Typography
        className="text-sm font-medium text-lstnBlue-500 cursor-pointer text-lstnGreen-500 flex items-center justify-center"
        placeholder="Your Projects"
        onClick={handleKeywordDialog}
      >
        <div className="inline-block lstn-btn w-3 h-3 p-0 rounded-full text-center relative mr-1">
          <svg width="12" height="12" aria-hidden="true">
            <use xlinkHref="#icon-pluss" />
          </svg>
        </div>
        {/* {t('projects.createFirst')} */}
        Monitor your first Keyword
      </Typography>
    </div>
  )
}
export interface KeywordDialogRef {
  handleKeywordClose: () => void
  handleKeywordOpen: () => void
  current?: {
    handleKeywordClose: () => void
    handleKeywordOpen: () => void
  }
}
const Keywords = forwardRef<KeywordDialogRef, {}>((props, ref) => {
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  console.log(props)
  const { keywords } = useAppSelector((store) => store.keyword)
  const { currentProject } = useAppSelector((store) => store.project)

  const [loading, setLoading] = useState(false)
  const [openAddKeyword, setOpenAddKeyword] = useState(false)
  const [selected, setSelected] = useState<Selection>({})

  const [keyword, setKeyword] = React.useState('')
  const [whitelistedDomain, setWhitelistedDomain] = React.useState<string>('')

  const handleKeywordDialog = () => setOpenAddKeyword(!openAddKeyword)
  const handleKeywordClose = () => setOpenAddKeyword(false)
  const handleKeywordOpen = () => setOpenAddKeyword(true)

  useEffect(() => {
    if (currentProject && currentProject.website) {
      setWhitelistedDomain(currentProject.website)
    }
  }, [currentProject])
  useImperativeHandle(ref, () => ({
    handleKeywordClose,
    handleKeywordOpen,
  }))

  // const countriesToString = (data: Selection) => {
  //   let allCountries: string[] = []
  //   for (const region in data) {
  //     allCountries = allCountries.concat(data[region].countries)
  //   }
  //   return allCountries.join(', ')
  // }
  const handleSubmit = async (id?: string) => {
    if (!id) throw Error('Keyword ID is required')
    if (keyword !== '' && Object.keys(currentProject).length !== 0) {
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      await axios
        .post(
          `${process.env.REACT_APP_FIREBASE_API}/keyword/addKeyword`,
          {
            data: {
              keyword: keyword,
              projectId: currentProject?.id,
              whitelistedDomain: whitelistedDomain,
              countriesToMonitor: selected,
              accountId: currentProject?.accountId,
              keywordId: id,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        .then(() => {
          handleKeywordDialog()
          setKeyword('')
          setWhitelistedDomain('')
          setSelected({})
          setLoading(false)
          Toast({
            title: 'Keyword Added Successfully',
            variant: 'success',
            options: { autoClose: 5000 },
          })
        })
        .catch((error: any) => {
          handleKeywordDialog()
          setKeyword('')
          setWhitelistedDomain('')
          setSelected({})
          setLoading(false)
          console.log(error)
          Toast({
            title: 'Failed. Something went wrong',
            variant: 'error',
            options: { autoClose: 5000 },
          })
        })
    }
  }

  const keyWordName = useMemo(() => {
    if (keywords.length > 0) {
      const keyword = keywords.find(
        (keyword) => keyword.id === params.keywordId,
      )
      return keyword?.title
    }
  }, [keywords, params.keywordId])

  const data = [
    {
      label: 'Active',
      value: 'active',
      desc: () => {
        return keywords.filter((keyword) => keyword.status === 'active')
          .length === 0 ? (
          // <KeywordPlaceholder handleKeywordDialog={handleKeywordDialog} />
          <Typography
            placeholder="Your Projects"
            className="text-base text-blue-gray-900 font-medium py-4"
          >
            No Active Keywords
          </Typography>
        ) : (
          <div className="justify-center flex flex-row flex-wrap xl:justify-between mb-6">
            {keywords
              .filter((keyword) => keyword.status === 'active')
              .map((keyword, index) => (
                <ProjectCard
                  key={index}
                  keyword={keyword}
                  // handleEdit={handleEdit}
                  // handleArchive={handleArchive}
                />
              ))}
          </div>
        )
      },
    },
    {
      label: 'Archived',
      value: 'archived',
      desc: () => {
        return (
          <>
            {keywords.filter((keyword) => keyword.status === 'archived')
              .length === 0 ? (
              // <KeywordPlaceholder handleKeywordDialog={handleKeywordDialog} />
              <Typography
                placeholder="Your Projects"
                className="text-base text-blue-gray-900 font-medium py-4"
              >
                No Archived Keywords
              </Typography>
            ) : (
              keywords
                .filter((keyword) => keyword.status === 'archived')
                .map((keyword, index) => (
                  <ProjectCard
                    key={index}
                    keyword={keyword}
                    // handleEdit={handleEdit}
                    // handleArchive={handleArchive}
                  />
                ))
            )}
          </>
        )
      },
    },
  ]

  return (
    <main className="projects-container flex flex-col">
      {params.keywordId ? (
        <>
          {location.pathname.includes('/edit') ? (
            <EditKeyword />
          ) : (
            <>
              <div className="flex justify-between items-center">
                <Typography
                  placeholder="Your Projects"
                  className="text-2xl text-blue-gray-900 font-medium"
                >
                  {keyWordName}
                </Typography>
                <Button
                  variant="outlined"
                  className="py-2 font-sans flex px-3 border-blue-600 text-blue-600"
                  onClick={() => {
                    navigate(`/keyword/${params.keywordId}/edit`)
                  }}
                >
                  <span className="text-base pr-2">
                    <img
                      src={keywordEdit}
                      alt="ProjectSettingsLogo"
                      className="w-4 h-4"
                    />
                  </span>
                  EDIT
                </Button>
              </div>
              <MetaKeyword />
            </>
          )}
        </>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <Typography
              placeholder="Your Projects"
              className="text-2xl text-blue-gray-900 font-medium"
            >
              Track a new keyword
            </Typography>
            {!openAddKeyword && (
              <Button
                color="green"
                variant="filled"
                className="py-2 font-sans"
                onClick={handleKeywordDialog}
              >
                <span className="text-base pr-4">+</span>
                Add new KEYWORD
              </Button>
            )}
          </div>
          {openAddKeyword ? (
            <StripeForm
              handleSubmit={handleSubmit}
              isEditing={false}
              loading={loading}
              setLoading={setLoading}
              keyword={keyword}
              setKeyword={setKeyword}
              whitelistedDomain={whitelistedDomain}
              setWhitelistedDomain={setWhitelistedDomain}
              selected={selected}
              setSelected={setSelected}
            />
          ) : keywords.length === 0 ? (
            <KeywordPlaceholder handleKeywordDialog={handleKeywordDialog} />
          ) : (
            <Tabs id="custom-animation" value="active" className="py-6">
              <TabsHeader placeholder={undefined} className=" w-full sm:w-96">
                {data.map(({ label, value }) => (
                  <Tab key={value} value={value} placeholder={undefined}>
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody
                placeholder={undefined}
                animate={{
                  initial: { y: 250 },
                  mount: { y: 0 },
                  unmount: { y: 250 },
                }}
              >
                {data.map(({ value, desc }) => (
                  <TabPanel key={value} value={value} className="p-0">
                    {desc()}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          )}
        </>
      )}
    </main>
  )
})
export default Keywords
