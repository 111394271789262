import { createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
import type { AppDispatch } from '../store'
// import firebase from 'firebase/compat/app'
import { AdInfo } from '../../interfaces'

interface adsState {
  ads: AdInfo[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
}

const initialState: adsState = {
  loading: 'idle',
  error: null,
  ads: [],
}

export const keywordSlice = createSlice({
  name: 'Ads',
  initialState,
  reducers: {
    setAllAds: (state, action) => {
      state.ads = action.payload
      state.loading = 'succeeded'
    },
    resetadsState: (state) => {
      state.ads = []
    },
  },
})

export const {
  // setCurrentAd,
  setAllAds,
  resetadsState,
} = keywordSlice.actions

export default keywordSlice.reducer

export const fetchAllAds = async (
  dispatch: AppDispatch,
): Promise<() => void> => {
  const adDocQuery = database.collection('ads')

  const unsubscribe = adDocQuery.onSnapshot((snapShot) => {
    const ads = snapShot.docs.map((doc) => {
      const parsedData = JSON.stringify(doc.data())
      const ad = {
        ...JSON.parse(parsedData),
        id: doc.id,
      }
      return ad
    })
    dispatch(setAllAds(ads))
  })

  return () => unsubscribe()
}

export const fetchAdvertiserById = async (id: string) => {
  const adDocQuery = database
    .collection('advertisers')
    .where('google_ads_id', '==', id)
  const adDocSnapshot = await adDocQuery.get()

  if (adDocSnapshot.empty) {
    console.log('No advertiser found with the given google_ads_id.')
    return null
  }
  const adDoc = adDocSnapshot.docs[0]
  const ad = {
    id: adDoc.id,
    ...adDoc.data(),
  }

  return ad
}
