import React from 'react'
import {
  Button,
  Typography,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { useTranslation } from 'react-i18next'
// import { customEvent } from '../../utils/customHooks'

interface IProps {
  projectName: string
  projectId: string
  handleArchive: (id: string) => void
  handleOpenArchive: () => void
  openArchiveDialog: boolean
  // // accountId: string
  // source: string
  // userId: string
}

const ArchiveConfirmDialog = ({
  projectName,
  projectId,
  handleArchive,
  handleOpenArchive,
  openArchiveDialog,
}: IProps): React.JSX.Element => {
  const { t } = useTranslation()

  // useEffect(() => {
  //   if (openArchiveDialog) {
  //     customEvent('project_archive_dialog_opened', {
  //       accountId: accountId,
  //       projectId: projectId,
  //       actionSource: source,
  //       user_userId: userId,
  //     })
  //   }
  // }, [accountId, openArchiveDialog, projectId, source, userId])

  return (
    <Dialog
      open={openArchiveDialog}
      handler={handleOpenArchive}
      placeholder={undefined}
      size="sm"
      className="px-6 py-4"
    >
      <DialogHeader placeholder={undefined}>
        {t('projects.archive')} {projectName}
      </DialogHeader>
      <DialogBody placeholder={undefined}>
        <Typography
          className="text-base font-normal text-blue-gray-500"
          placeholder={undefined}
        >
          {t('projects.confirmArchive')}
        </Typography>
      </DialogBody>
      <DialogFooter placeholder={undefined}>
        <Button
          variant="text"
          color="black"
          onClick={handleOpenArchive}
          className="mr-1"
          placeholder={undefined}
        >
          {t('createProject.cancel')}
        </Button>
        <Button
          variant="gradient"
          color="red"
          onClick={() => {
            handleArchive(projectId)
            handleOpenArchive()
          }}
          placeholder={undefined}
        >
          {t('projects.archive')}
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default ArchiveConfirmDialog
