import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from './CheckoutForm'
import { IKeyword } from '../../store/features/keywordSlice'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!)
type Selection = {
  [region: string]: {
    isFullySelected: boolean
    countries: string[]
  }
}

interface IProps {
  handleSubmit: (id?: string) => void
  isEditing: boolean
  loading: boolean
  setLoading: (val: boolean) => void
  keyword: string
  setKeyword: (val: string) => void
  whitelistedDomain: string
  setWhitelistedDomain: (val: string) => void
  selected: Selection | any
  setSelected: (val: Selection) => void
  handleUpdate?: (id: string, data: IKeyword) => void
}
export default function StripeForm({
  handleSubmit,
  isEditing,
  loading,
  setLoading,
  keyword,
  setKeyword,
  whitelistedDomain,
  setWhitelistedDomain,
  selected,
  setSelected,
  handleUpdate,
}: IProps) {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm
        handleSubmit={handleSubmit}
        isEditing={isEditing}
        loading={loading}
        setLoading={setLoading}
        keyword={keyword}
        setKeyword={setKeyword}
        whitelistedDomain={whitelistedDomain}
        setWhitelistedDomain={setWhitelistedDomain}
        selected={selected}
        setSelected={setSelected}
        {...(handleUpdate ? { handleUpdate: handleUpdate } : '')}
      />
    </Elements>
  )
}
