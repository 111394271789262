import React, { useEffect, useMemo, useState } from 'react'
import CustomTooltip from '../Tooltip/Tooltip'
import Typography from '../Typography/Typography'
import { Button } from '@material-tailwind/react'
// import { AdInfo } from '../../interfaces'
import { Iincident, IKeyword } from '../../store/features/keywordSlice'
import { fromUnixTime, startOfDay } from 'date-fns'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../store/store'
import { formatInTimeZone } from '../../utils/customHooks'

const MonitorButton = ({
  day,
  // ads,
  incident,
  handleChangeTab,
}: {
  day: number
  // ads: AdInfo[]
  incident: Iincident[] | undefined
  handleChangeTab: (value: string) => void
}) => {
  const [open, setOpen] = useState(false)
  const [keyword, setKeyword] = useState<IKeyword>()

  const { keywords } = useAppSelector((store) => store.keyword)

  const params = useParams()
  const date = new Date()
  date.setDate(day)

  const timestamp = formatInTimeZone(startOfDay(date), 'Europe/Rome')

  // const adCount = useMemo(() => {
  //   return ads.filter((ad) => {
  //     const createdAt = ad.createdAt
  //     const createdAtDate =
  //       createdAt && createdAt.seconds
  //         ? new Date(createdAt.seconds * 1000) // Convert seconds to milliseconds
  //         : null
  //     return createdAtDate && getUnixTime(createdAtDate) <= getUnixTime(date)
  //   })
  // }, [ads, date])

  const incidentCount = useMemo(() => {
    return incident?.filter((incident) => {
      const createdAt = incident.createdAt
      const createdAtDate =
        createdAt && createdAt.seconds
          ? new Date(createdAt.seconds * 1000) // Convert seconds to milliseconds
          : null
      if (createdAtDate) {
        createdAtDate.setDate(createdAtDate.getDate()) // Add 1 day
      }
      return (
        createdAtDate && createdAtDate.toDateString() === date.toDateString()
      )
    })
  }, [incident, date])

  const incidentRepeated = useMemo(() => {
    if (incident) {
      return incident.filter((incidentObject) => {
        return incidentObject.incidentDates?.some(
          (incidentDate: { lastSeen: number }) => {
            const createdAt = fromUnixTime(incidentDate.lastSeen)
            return createdAt && createdAt.toDateString() === date.toDateString()
          },
        )
      })
    }
    return []
  }, [incident, date])

  useEffect(() => {
    if (params.keywordId) {
      const keyword = keywords.find(
        (keyword) => keyword.id === params.keywordId,
      )
      setKeyword(keyword)
    }
  }, [params, keywords])

  const totalIncidents =
    (incidentCount?.length ?? 0) + (incidentRepeated?.length ?? 0)
  return (
    <CustomTooltip
      // key={member.id}
      open={open}
      content={
        <div
          className="items-start justify-start flex flex-col "
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Typography color="black" className="font-medium mb-3">
            {date.toDateString()}
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className="font-normal opacity-80"
          >
            {keyword?.scanData && keyword?.scanData[timestamp]
              ? keyword.scanData![timestamp]
              : 0}{' '}
            Ads monitored
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className="font-normal opacity-80"
          >
            {totalIncidents} Incidents found
          </Typography>
          <Typography
            variant="small"
            color="gray"
            className="font-normal opacity-80"
          >
            -- resolutions
          </Typography>
          <Button
            className="text-well_primary-500 font-medium p-0 bg-transparent shadow-none hover:shadow-none"
            placeholder="View details"
            onClick={() => {
              handleChangeTab('violation')
              setOpen(false)
            }}
          >
            View details
          </Button>
        </div>
      }
      className="bg-white shadow-md p-6 text-left group"
    >
      <div
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        className={`w-3 h-14 rounded cursor-pointer ${
          incidentCount?.length! > 0 || incidentRepeated?.length! > 0
            ? '!bg-red-500 border-red-500'
            : 'bg-wellGreen-500 border-wellGreen-500 '
        }`}
      ></div>
    </CustomTooltip>
  )
}

export default MonitorButton
