import { Button, Input, Textarea, Typography } from '@material-tailwind/react'
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { useStripe, useElements } from '@stripe/react-stripe-js'
import { StripeElementChangeEvent } from '@stripe/stripe-js'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { functions } from '../../firebaseApp'
import { useAppSelector } from '../../store/store'
// import EditButtonDialog from './EditDialog'
import { v4 as uuid } from 'uuid'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { DialogWithCountriesMonitorSelector } from '../Keyword/CountriesMonitorMultiSelect'
import { useParams } from 'react-router-dom'
import { IKeyword } from '../../store/features/keywordSlice'
import firebase from 'firebase/compat/app'

interface IProps {
  handleSubmit: (id?: string) => void
  isEditing: boolean
  loading: boolean
  setLoading: (val: boolean) => void
  keyword: string
  setKeyword: (val: string) => void
  whitelistedDomain: string
  setWhitelistedDomain: (val: string) => void
  selected: Selection
  setSelected: (val: any) => void
  handleUpdate?: (id: string, data: IKeyword | any) => void
}
type Selection = {
  [region: string]: {
    isFullySelected: boolean
    countries: string[]
  }
}
const CheckoutForm = ({
  handleSubmit,
  isEditing,
  loading,
  setLoading,
  keyword,
  setKeyword,
  whitelistedDomain,
  setWhitelistedDomain,
  selected,
  setSelected,
  handleUpdate,
}: IProps) => {
  const { currentAccount } = useAppSelector((store) => store.account)
  const { keywords } = useAppSelector((store) => store.keyword)
  const { sortedRegions } = useAppSelector((store) => store.plan)

  const [openCountriesMonitorDialog, setOpenCountriesMonitorDialog] =
    React.useState(false)
  const [totalPrices, setTotalPrices] = useState(0)
  const [pricesIds, setPricesIds] = useState<string[]>([])
  // const [selectedSave, setSelectedSave] = useState(false)

  const params = useParams()

  const getActivePlanIds = useMemo(() => {
    if (currentAccount.stripeSubscriptionData && params.keywordId) {
      const items =
        currentAccount.stripeSubscriptionData[params.keywordId!]?.items?.data
      return items?.map((item: { price: { id: string } }) => item?.price?.id)
    }
  }, [currentAccount, currentAccount.stripeSubscriptionData, params])
  //  console.log(getActivePlanIds,'acrive price ids')
  const [submissionError, setSubmissionError] = useState('')
  const [cardholderName, setCardholderName] = useState('')
  const stripe = useStripe()
  const elements = useElements()

  const regions = useMemo(() => {
    return [...sortedRegions]
  }, [sortedRegions])

  const [cardErrors, setCardErrors] = useState({
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
    cardholderName: '',
    keyword: '',
  })

  const [disableBtn, setDisableBtn] = useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
    cardholderName: true,
    keyword: isEditing ? false : true,
  })

  const today = new Date()
  const expire = new Date(today)
  expire.setDate(today.getDate() + 30)
  const formattedExpireDate = expire.toLocaleDateString()

  const handleCardholderNameChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value
    const id = e.target.id

    if (id === 'keyword') {
      setKeyword(value)
      if (value.trim() === '') {
        setCardErrors((prev) => ({
          ...prev,
          keyword: 'Keyword is required.',
        }))
        setDisableBtn((prevDisableBtn) => ({
          ...prevDisableBtn,
          keyword: true,
        }))
      } else {
        setCardErrors((prev) => ({
          ...prev,
          keyword: '',
        }))
        setDisableBtn((prevDisableBtn) => ({
          ...prevDisableBtn,
          keyword: false,
        }))
      }
    } else if (id === 'cardholderName') {
      setCardholderName(value)
      if (value.trim() === '') {
        setCardErrors((prev) => ({
          ...prev,
          cardholderName: 'Cardholder name is required.',
        }))
        setDisableBtn((prevDisableBtn) => ({
          ...prevDisableBtn,
          cardholderName: true,
        }))
      } else {
        setCardErrors((prev) => ({
          ...prev,
          cardholderName: '',
        }))
        setDisableBtn((prevDisableBtn) => ({
          ...prevDisableBtn,
          cardholderName: false,
        }))
      }
    }
  }

  const handleStripeElementChange = (event: StripeElementChangeEvent) => {
    const { elementType, complete, error } = event
    const elementName = elementType.replace(/([A-Z])/g, ' $1').toLowerCase()

    if (error) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: error.message,
      }))
      setDisableBtn((prevDisableBtn) => ({
        ...prevDisableBtn,
        [elementType]: true,
      }))
    } else if (!complete) {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: `Your ${elementName} is required.`,
      }))
      setDisableBtn((prevDisableBtn) => ({
        ...prevDisableBtn,
        [elementType]: true,
      }))
    } else {
      setCardErrors((prevErrors) => ({
        ...prevErrors,
        [elementType]: '',
      }))
      setDisableBtn((prevDisableBtn) => ({
        ...prevDisableBtn,
        [elementType]: false,
      }))
    }
  }
  const getPaymentIntent = async ({
    accountId,
    pricesIds,
    operation,
    keywordId,
    removePriceIds,
  }: {
    accountId: string
    pricesIds?: string[]
    operation?: string
    keywordId?: string
    removePriceIds?: string[]
  }) => {
    try {
      const payments = functions.httpsCallable('payments')
      const payload = {
        accountId,
        ...(operation !== 'get-payment-methods' && { priceIds: pricesIds }),
        ...(keywordId && { keywordId: keywordId }),
        ...(removePriceIds && { removePriceIds: removePriceIds }),
      }
      const response = await payments({
        operation: operation,
        payload,
      })
      const secret = response.data
      return secret
    } catch (error) {
      console.log(error)
    }
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    // setSelectedSave(false)
    setLoading(true)
    if (!stripe || !elements) {
      setSubmissionError('Stripe has not loaded')
      return
    }

    try {
      const accountId = currentAccount?.id
      const checkKeywordId = params.keywordId
      // const checkKeywordId = keywords[0]?.id
      const accountStatus = checkKeywordId
        ? currentAccount.subscriptionStatus[checkKeywordId as any]
        : '--'
      const cardElement =
        accountStatus === 'active'
          ? (
              await getPaymentIntent({
                accountId,
                operation: 'get-payment-methods',
              })
            ).data[0]
          : elements.getElement(CardNumberElement)

      if (!cardElement) {
        throw new Error('Card details not available')
      }

      if (pricesIds.length === 0) {
        throw new Error('Invalid pricesIds')
      }
      const keywordId = uuid()
      const operationType = isEditing
        ? 'update-subscription'
        : 'new-subscription'

      const activePlan = getActivePlanIds
      const removedPlanIds = getActivePlanIds?.filter(
        (item: string) => !pricesIds.includes(item),
      )
      const uniqueValues = pricesIds.filter(
        (item) => !activePlan?.includes(item),
      )
      // console.log(uniqueValues, 'unique pricesIds')
      // console.log(pricesIds, 'pricesIds')
      // console.log(activePlan, 'activePlan')
      // console.log(removedPlanIds, 'removedPlanIds')
      const paymentIntent = await getPaymentIntent({
        accountId,
        pricesIds: isEditing ? uniqueValues : pricesIds,
        operation: operationType,
        keywordId: isEditing ? params.keywordId : keywordId,
        ...(isEditing && { removePriceIds: removedPlanIds || [] }),
      })

      const clientSecret = paymentIntent.clientSecret
      if (!clientSecret) {
        throw new Error('Invalid Payment Intent')
      }

      const { error } = await stripe.confirmCardPayment(clientSecret, {
        payment_method:
          accountStatus === 'active'
            ? cardElement.card.id
            : {
                card: cardElement,
                billing_details: {
                  name: cardholderName,
                },
              },
      })

      if (error) {
        console.error('Payment error:', error)
        setSubmissionError(error.message || 'An error occurred during payment.')
        setLoading(false)
      } else {
        if (!error) {
          handleSubmit(keywordId)
        }
      }
    } catch (error) {
      console.error('Error:', error)
      setLoading(false)
      setSubmissionError(
        (error as Error).message || 'An unexpected error occurred.',
      )
    }
  }
  // console.log(currentAccount.subscriptionStatus)
  const handleOpenCountriesMonitorDialog = useCallback(
    () => setOpenCountriesMonitorDialog(!openCountriesMonitorDialog),
    [openCountriesMonitorDialog],
  )

  const calculateTotalPrize = useCallback(() => {
    let total = 0
    let ids: string[] = []
    if (selected && selected.Worldwide && selected.Worldwide.isFullySelected) {
      const region = regions.find(
        (r: { name: string }) => r.name === 'Worldwide',
      )
      if (region && region.prices) {
        total = region.prices[0].unit_amount! / 100
        ids.push(region.prices[0].id)
      }
    } else {
      Object.entries(selected).forEach(
        ([regionName, { isFullySelected, countries }]) => {
          const region = regions.find(
            (r: { name: string }) => r.name === regionName,
          )
          if (isFullySelected) {
            if (region && region.prices) {
              total += region.prices[0].unit_amount! / 100 || 0
              if (region && region.prices) {
                ids.push(region.prices[0].id)
              }
            }
          } else {
            countries.forEach((code) => {
              const countryPrize =
                region?.countries.find(
                  (c: { stripe_metadata_countryCode: string }) =>
                    c.stripe_metadata_countryCode === code,
                )?.prices[0].unit_amount! / 100 || 0
              total += countryPrize
              const country = region?.countries.find(
                (c: { stripe_metadata_countryCode: string }) =>
                  c.stripe_metadata_countryCode === code,
              )
              if (country && country.prices) {
                ids.push(country.prices[0].id)
              }
            })
          }
        },
      )
    }
    return { total, ids }
  }, [regions, selected])

  useEffect(() => {
    const { total, ids } = calculateTotalPrize()
    setTotalPrices(total)
    setPricesIds(ids)
  }, [calculateTotalPrize, selected, regions])

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <div className="w-96 mt-10 space-y-6">
          <div className="mb-1 flex flex-col">
            <Typography
              variant="h6"
              className="mb-1 text-sm font-medium text-wellBlueGray-900"
              placeholder={undefined}
            >
              Keyword
            </Typography>
            <Input
              autoFocus
              type="text"
              size="lg"
              id="keyword"
              value={keyword}
              placeholder="e.g. Salesforce"
              className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
              labelProps={{
                className: 'hidden',
              }}
              style={{ borderRadius: '8px' }}
              onChange={handleCardholderNameChange}
              crossOrigin={undefined}
              required
              disabled={isEditing}
            />
            {cardErrors.keyword && (
              <p className="mt-1 text-sm font-semibold text-red-500">
                {cardErrors.keyword}
              </p>
            )}
          </div>
          <div className="mb-1 flex flex-col my-3">
            <Typography
              variant="h6"
              className="mb-1 text-sm font-medium text-wellBlueGray-900"
              placeholder={undefined}
            >
              Whitelisted domains
            </Typography>
            <Textarea
              autoFocus
              variant="outlined"
              rows={2}
              value={whitelistedDomain}
              placeholder="Add here the domains that should not trigger a violation. E.g. slack.com, g2.com, ..."
              className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
              labelProps={{
                className: 'hidden',
              }}
              style={{ borderRadius: '8px' }}
              onChange={(e) => {
                if (isEditing && handleUpdate) {
                  setWhitelistedDomain(e.target.value)
                  handleUpdate(params.keywordId!, {
                    whitelistedDomain: e.target.value,
                    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  })
                } else {
                  setWhitelistedDomain(e.target.value)
                }
              }}
              // crossOrigin={undefined}
            />
          </div>
          <div className="mb-1 flex flex-col my-3 space-y-2">
            <Typography
              variant="h6"
              className="mb-1 text-sm font-medium text-wellBlueGray-900"
              placeholder={undefined}
            >
              Countries to monitor
            </Typography>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleOpenCountriesMonitorDialog}
              className={`text-lstnGreen-500 border-lstnGreen-500 w-fit ${
                Object.entries(selected).length > 0 ? 'hidden' : 'block'
              }`}
              placeholder={undefined}
            >
              ADD COUNTRIES
            </Button>
            {Object.entries(selected).length > 0 && (
              <div className="mt-4 text-wellBlueGray-600">
                <Typography
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-600"
                  placeholder={undefined}
                >
                  Currently monitoring:
                </Typography>
                <ul className="text-sm">
                  {Object.entries(selected).map(
                    ([region, { isFullySelected, countries }]) => (
                      <li key={region} className="flex items-center">
                        <FiberManualRecordIcon className="!text-[6px] text-black mr-2" />
                        {region}
                        {isFullySelected
                          ? ' (All)'
                          : `: ${countries.join(', ')}`}
                      </li>
                    ),
                  )}
                </ul>
                <Typography
                  variant="h6"
                  className="mb-1 text-sm font-medium text-wellBlueGray-600"
                  placeholder={undefined}
                >
                  Total spend: ${totalPrices} / month
                </Typography>
                {totalPrices == 0 && (
                  <Typography
                    variant="h6"
                    className="!mt-1 text-sm font-medium text-red-500"
                    placeholder={undefined}
                  >
                    please add countries to monitor
                  </Typography>
                )}
              </div>
            )}
            <Button
              variant="outlined"
              size="sm"
              onClick={handleOpenCountriesMonitorDialog}
              placeholder={undefined}
              className={`text-lstnGreen-500 border-lstnGreen-500 mt-2 !mb-4 w-fit ${
                Object.entries(selected).length > 0 ? 'block' : 'hidden'
              }`}
            >
              EDIT COUNTRIES
            </Button>
            <div
              className={`my-4 ${
                Object.entries(selected).length > 0 ? 'block' : 'hidden'
              }`}
            >
              <div className="flex flex-col mb-2">
                {isEditing &&
                params &&
                params.keywordId &&
                currentAccount.subscriptionStatus[params?.keywordId! as any] ==
                  'active' ? (
                  // <div className="w-full flex flex-col justify-center">
                  //   <Typography
                  //     variant="h6"
                  //     className="mb-1 text-sm font-medium text-wellBlueGray-600"
                  //     placeholder={undefined}
                  //   >
                  //     We will charge ${totalPrices} immediately your card ending
                  //     in {formattedExpireDate}, and then on a monthly basis. You
                  //     can stop it anytime
                  //   </Typography>
                  //   {/* Submit Button */}
                  //   <Button
                  //     type="submit"
                  //     color="blue"
                  //     className="w-fit mb-10 mt-2"
                  //     style={{ borderRadius: '8px' }}
                  //     // onClick={handleSubmit}/
                  //     loading={loading}
                  //     placeholder={undefined}
                  //     disabled={totalPrices === 0 || disableBtn.keyword}
                  //   >
                  //     {loading
                  //       ? 'Processing...'
                  //       : isEditing
                  //         ? 'SAVE CHANGES'
                  //         : 'START MONITORING'}
                  //   </Button>
                  // </div>
                  ''
                ) : (
                  <>
                    <Typography
                      variant="h6"
                      className="mb-1 text-sm font-medium text-wellBlueGray-900"
                      placeholder={undefined}
                    >
                      Billing
                    </Typography>
                    <Typography
                      variant="h6"
                      className="mb-1 text-sm font-medium text-wellBlueGray-600"
                      placeholder={undefined}
                    >
                      A credit card is needed to start keyword monitoring
                    </Typography>
                    <div className="my-4 space-y-4 ">
                      <Typography
                        variant="h6"
                        className=" text-sm font-medium text-lstnBlueGray-900"
                        placeholder={undefined}
                      >
                        Card Number
                      </Typography>
                      <div className="mt-2">
                        <div className="h-10 w-full text-lstnBlueGray-900 border !border-blue-gray-200 focus:!border-gray-400 rounded-md bg-transparent p-[10px] pl-4">
                          <CardNumberElement
                            id="cardNumber"
                            options={{
                              showIcon: true,
                            }}
                            onChange={handleStripeElementChange}
                          />
                        </div>
                        {cardErrors.cardNumber && (
                          <p className="mt-1 text-sm font-semibold text-red-500">
                            {cardErrors.cardNumber}
                          </p>
                        )}
                      </div>
                      {/* Expiration Date and CVV Fields */}
                      <div className="flex w-full gap-2">
                        <div className="!w-1/2 overflow-hidden">
                          <Typography
                            variant="h6"
                            className="mb-2 text-sm font-medium text-lstnBlueGray-900"
                            placeholder={undefined}
                          >
                            Expires
                          </Typography>
                          <div className="h-10 w-full rounded-md border !border-blue-gray-200 focus:!border-gray-400 placeholder:text-blue-gray-500 placeholder:opacity-100 bg-transparent p-[10px] pl-4">
                            <CardExpiryElement
                              id="cardExpiry"
                              onChange={handleStripeElementChange}
                            />
                          </div>
                          {cardErrors.cardExpiry && (
                            <p className="mt-1 text-sm font-semibold text-red-500">
                              {cardErrors.cardExpiry}
                            </p>
                          )}
                        </div>
                        <div className="!w-1/2 overflow-hidden">
                          <Typography
                            variant="h6"
                            className="mb-2 text-sm font-medium text-lstnBlueGray-900"
                            placeholder={undefined}
                          >
                            CVC
                          </Typography>
                          <div className="h-10 w-full rounded-md border !border-blue-gray-200 focus:!border-gray-400 placeholder:text-blue-gray-500 placeholder:opacity-100 bg-transparent p-[10px] pl-4">
                            <CardCvcElement
                              id="cardCvc"
                              onChange={handleStripeElementChange}
                            />
                          </div>
                          {cardErrors.cardCvc && (
                            <p className="mt-1 text-sm font-semibold text-red-500">
                              {cardErrors.cardCvc}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* Cardholder Name Field */}
                      <div>
                        <Typography
                          variant="h6"
                          className="mb-2 text-sm font-medium text-lstnBlueGray-900"
                          placeholder={undefined}
                        >
                          Holder Name
                        </Typography>
                        <Input
                          type="text"
                          size="lg"
                          placeholder="e.g. John Doe"
                          id="cardholderName"
                          value={cardholderName}
                          onChange={handleCardholderNameChange}
                          className="text-lstnBlueGray-900 !border-blue-gray-200  focus:!border-gray-400 placeholder:text-blue-gray-500 placeholder:opacity-100"
                          labelProps={{
                            className: 'hidden',
                          }}
                          crossOrigin={undefined}
                          style={{ borderRadius: '8px' }}
                        />
                        {cardErrors.cardholderName && (
                          <p className="mt-1 text-sm font-semibold text-red-500">
                            {cardErrors.cardholderName}
                          </p>
                        )}
                      </div>
                      {submissionError && (
                        <p className="!mt-0 text-sm font-semibold text-red-500">
                          {submissionError}
                        </p>
                      )}
                    </div>
                    <div>
                      {keywords.length == 0 ? (
                        <Typography
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-600"
                          placeholder={undefined}
                        >
                          The first 30 days have a 90% discount! We will charge
                          ${totalPrices * 0.1} &nbsp; immediately and then{' '}
                          {totalPrices}
                          $/month from the next month onward. You can stop it
                          anytime.
                        </Typography>
                      ) : (
                        <Typography
                          variant="h6"
                          className="mb-1 text-sm font-medium text-wellBlueGray-600"
                          placeholder={undefined}
                        >
                          We will charge ${totalPrices} immediately your card
                          ending in
                          {formattedExpireDate}, and then on a monthly basis.
                          You can stop it anytime
                        </Typography>
                      )}

                      {/* Submit Button */}
                      <Button
                        type="submit"
                        color="blue"
                        className="w-fit mb-10 mt-2"
                        style={{ borderRadius: '8px' }}
                        loading={loading}
                        placeholder={undefined}
                        disabled={
                          disableBtn.cardCvc ||
                          disableBtn.cardExpiry ||
                          disableBtn.cardNumber ||
                          disableBtn.cardholderName ||
                          disableBtn.keyword ||
                          totalPrices === 0
                        }
                      >
                        {loading
                          ? 'Processing...'
                          : isEditing
                            ? 'SAVE CHANGES'
                            : 'START MONITORING'}
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
      <DialogWithCountriesMonitorSelector
        open={openCountriesMonitorDialog}
        handleOpen={handleOpenCountriesMonitorDialog}
        selected={selected}
        setSelected={setSelected}
        setTotalPrices={setTotalPrices}
        pricesIds={pricesIds}
        setPricesIds={setPricesIds}
        // setSelectedSave={setSelectedSave}
        isEditing={isEditing}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}

export default CheckoutForm
