import { createSlice } from '@reduxjs/toolkit'
import { database } from '../../firebaseApp'
// import firebase from 'firebase'
// import { IProject } from '../../interfaces'
import type { AppDispatch } from '../store'
// import { customEvent } from '../../utils/customHooks'
import firebase from 'firebase/compat/app'
import { AdInfo } from '../../interfaces'
import { Status } from '../../enums'

export interface IKeyword {
  whitelistedDomain?: string
  countriesToMonitor?: string
  title: string
  projectId: string
  createdAt: firebase.firestore.Timestamp
  updatedAt: firebase.firestore.Timestamp
  trackedSince: firebase.firestore.Timestamp
  status: string
  id?: string
  scanData?: { [keywordId: string]: number }
}

export interface IAdvertiser {
  createdAt?: Date
  google_ads_id: string
  id: string
  legal_name: string
  updatedAt?: Date
}

export interface Iincident {
  ad_domain_displayed: string
  ad_id: string
  ad_subtitle: string
  ad_title: string
  advertiser_id: string
  createdAt: firebase.firestore.Timestamp
  id: string
  incidentDates?: any
  status: Status
  updatedAt: firebase.firestore.Timestamp
  violationUrl: string
  docId?: string
}

interface ProjectsState {
  keywords: IKeyword[]
  loading: 'idle' | 'pending' | 'succeeded' | 'failed'
  error: string | null | undefined
  currentKeywordIncidents: Iincident[]
  roles: { [key: string]: string[] }
}

const initialState: ProjectsState = {
  keywords: [],
  loading: 'idle',
  error: null,
  currentKeywordIncidents: [] as Iincident[],
  roles: {} as { [key: string]: string[] },
}

export const keywordSlice = createSlice({
  name: 'keywords',
  initialState,
  reducers: {
    setCurrentKeywordIncidents: (state, action) => {
      state.currentKeywordIncidents = action.payload
    },
    setAllKeywords: (state, action) => {
      state.keywords = action.payload
      state.loading = 'succeeded'
    },
    resetkeywordsState: (state) => {
      state.keywords = []
      state.currentKeywordIncidents = [] as Iincident[]
    },
    setRoles: (state, action) => {
      state.roles = action.payload
    },
  },
})

export const {
  setCurrentKeywordIncidents,
  setAllKeywords,
  resetkeywordsState,
  setRoles,
} = keywordSlice.actions

export default keywordSlice.reducer

export const fetchAllKeywords = async (
  accountId: string,
  dispatch: AppDispatch,
): Promise<() => void> => {
  const projDocQuery = database
    .collection('keywords')
    .where(`accountId`, '==', accountId)

  const unsubscribe = projDocQuery.onSnapshot((snapShot) => {
    const keywords = snapShot.docs.map((doc) => {
      const parsedData = JSON.stringify(doc.data())
      const keyword = {
        ...JSON.parse(parsedData),
        id: doc.id,
      }
      return keyword
    })
    dispatch(setAllKeywords(keywords))
  })

  return () => unsubscribe()
}

export const fetchKeywordSubCollections = async (
  Id: string,
  dispatch: AppDispatch,
) => {
  const keyDocRef = database.collection('keywords').doc(Id)
  const incidentsLogRef = keyDocRef.collection('incidentsLog')
  try {
    const unsubscribe = incidentsLogRef.onSnapshot(
      (snapshot) => {
        if (snapshot.empty) {
          console.log('No incidents found in the incidentsLog subcollection.')
          dispatch(setCurrentKeywordIncidents([]))
          return
        }
        const incidentsData: any[] = []
        snapshot.forEach((doc) => {
          incidentsData.push({ docId: doc.id, ...doc.data() })
        })

        dispatch(setCurrentKeywordIncidents(incidentsData))
      },
      (error) => {
        console.error('Error fetching incidentsLog in real-time: ', error)
      },
    )
    return unsubscribe
  } catch (error) {
    console.error('Error fetching incidentsLog: ', error)
  }
}

export const fetchRoles = async (dispatch: AppDispatch): Promise<void> => {
  const roleDoc = await database.collection('roles').doc('keyword').get()
  const roles = roleDoc.data()
  dispatch(setRoles(roles))
}

export const archiveKeyword = async (id: string): Promise<void> => {
  await database.collection('keywords').doc(id).update({ status: 'archived' })
}

export const getAdById = async (id: string): Promise<AdInfo | null> => {
  const querySnapshot = await database
    .collection('ads')
    .where('ad_creative_id', '==', id)
    .get()
  if (querySnapshot.empty) {
    return null
  }
  const doc = querySnapshot.docs[0]
  return doc.data() as IAdvertiser | any
}
