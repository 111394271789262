import React from 'react'
import '../../css/SignUp.css'
import Typography from '../Typography/Typography'
import LanguageSelector from './Select'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import firebase from '../../firebaseApp'
import { Link } from 'react-router-dom'
import i18n from '../../i18n'
import { Input } from '@material-tailwind/react'
import Sidebar from './Sidebar'
import { customEvent } from '../../utils/customHooks'
import ReactGA from 'react-ga4'
import Button from '../Button/TailwindButton'
import { useTranslation } from 'react-i18next'
import Toast from '../Toast/Toast'

const trackingCode: string | undefined = process.env.REACT_APP_GA4_PROPERTY_ID

if (trackingCode) {
  ReactGA.initialize([
    {
      trackingId: trackingCode,
    },
  ])
}
type Language = 'en' | 'fr' | 'es'

const ResetComponent: React.FC = () => {
  const { t } = useTranslation()
  const handleLanguageSelect = async (value: string): Promise<void> => {
    const ln = value || ''
    // setDisplayLanguage(ln as )
    i18n.changeLanguage(ln as Language)
    localStorage.setItem('wellshared-display-lng', ln as Language)
  }

  // Email reset form
  const emailResetFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      customEvent('password_reset_link_sent', {
        category: 'auth',
      })
      try {
        firebase
          .auth()
          .sendPasswordResetEmail(values.email)
          .then(() => {
            // console.log('Email sent')
            Toast({
              title: t('auth.resetPassword.toastTitle'),
              text: t('auth.resetPassword.toastContent'),
              variant: 'success',
              options: { autoClose: 15000 },
            })
            resetForm()
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        console.log(error)
      }
    },
  })

  return (
    <>
      <div className="flex ">
        <div className="hidden h-screen bg-gradient-to-t from-sidebar_gradient-from to-sidebar_gradient-to  md:flex lg:w-1/3 md:w-2/5 flex-col justify-between">
          <Sidebar />
        </div>
        <div className="w-full lg:w-3/4 md:w-3/5 p-6 h-screen flex justify-center items-center relative ">
          <div className="absolute right-10 top-8">
            <LanguageSelector onSelect={handleLanguageSelect} />
          </div>
          <div className="md:w-1/2 mx-auto sm:w-96 lg:w-2/5 xl:w-2/5 md:h-auto ">
            <Typography className="2xl:p-2 text-center text-3xl font-semibold text-lstnBlueGray-900">
              {t && t('auth.resetPassword.title')}
            </Typography>

            <Typography
              variant="paragraph"
              className="p-2 py-4 text-blue-gray-700 text-center text-base font-normal"
            >
              {t && t('auth.resetPassword.content')}
            </Typography>
            <div>
              <form onSubmit={emailResetFormik.handleSubmit}>
                <div className="w-full flex flex-col gap-8">
                  {/* Email reset form */}
                  <div>
                    <Typography
                      variant="h6"
                      className="mb-2 text-sm font-normal text-lstnBlueGray-900"
                    >
                      {t && t('auth.yourEmail')}
                    </Typography>

                    <Input
                      size="lg"
                      className=" !border-lstnBlueGray-100 focus:!border-gray-900 placeholder:text-blue-gray-500 placeholder:opacity-100"
                      type="email"
                      placeholder="Email"
                      autoCapitalize="none"
                      labelProps={{
                        className: 'hidden',
                      }}
                      name="email"
                      value={emailResetFormik.values.email}
                      onChange={emailResetFormik.handleChange}
                      onBlur={emailResetFormik.handleBlur}
                      crossOrigin={undefined}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault()
                          emailResetFormik.handleSubmit()
                        }
                      }}
                    />
                    {emailResetFormik.touched.email &&
                      emailResetFormik.errors.email && (
                        <Typography
                          color="gray"
                          className="mt-2 flex items-center gap-1 text-xs font-normal"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            className="-mt-px h-4 w-4"
                          >
                            <path
                              fillRule="evenodd"
                              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z"
                              clipRule="evenodd"
                            />
                          </svg>
                          {emailResetFormik.errors.email}
                        </Typography>
                      )}
                  </div>
                  <Button
                    type="submit"
                    disabled={emailResetFormik.isSubmitting}
                    className="w-full bg-green text-sm font-bold"
                  >
                    {t && t('auth.resetPassword.title')}
                  </Button>
                  <div className="mt-8 text-center text-base text-lstnBlueGray-700 font-normal">
                    {t && t('auth.resetPassword.foundPassword')}{' '}
                    <Link
                      to="/login"
                      className="font-medium text-gray-900 hover:text-blue-gray-900"
                    >
                      {t && t('auth.resetPassword.returnToLogin')}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetComponent
