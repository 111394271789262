import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import userSlice from './features/userSlice'
import folderHeirarchySlice from './features/folderSlice'
import { folderSliceReducer } from './features/folderSlice'
import transcriptReducerSlice from './features/transcript/transcriptsSlice'
import paragraphSlice from './features/paragraphSlice'
import { transcript } from './features/transcript/transcriptsSlice'
import accountSlice from './features/accountSlice'
import planSlice from './features/planSlice'
import projectSlice from './features/projectSlice'
import mentionSlice from './features/mentionSlice'
import inviteSlice from './features/inviteSlice'
import contentSlice from './features/contentSlice'
import contentIdeasSlice from './features/contentIdeaSlice'
import keywordSlice from './features/keywordSlice'
import adSlice from './features/adSlice'
// import thunk, {ThunkMiddleware } from 'redux-thunk'

const rootReducer = combineReducers({
  user: userSlice,
  folderHierarchy: folderHeirarchySlice,
  folder: folderSliceReducer,
  transcriptSlice: transcriptReducerSlice,
  paragraphsSlice: paragraphSlice,
  getTranscript: transcript,
  account: accountSlice,
  plan: planSlice,
  project: projectSlice,
  mentions: mentionSlice,
  invite: inviteSlice,
  content: contentSlice,
  contentIdeas: contentIdeasSlice,
  keyword: keywordSlice,
  ad: adSlice,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV !== 'production',
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export default store
