import React from 'react'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from '@material-tailwind/react'
import { Input } from '@material-tailwind/react'
import axios from 'axios'
import firebase from 'firebase/compat/app'
import Toast from '../Toast/Toast'
// import { useAppSelector } from '../../store/store'
import Typography from '../Typography/Typography'

export function AddKeyword({
  openKeywordDialog,
  handleKeywordDialog,
}: {
  openKeywordDialog: boolean
  handleKeywordDialog: () => void
}) {
  const [loading, setLoading] = React.useState(false)
  const [advertiserId, setAdvertiserId] = React.useState<string>('')
  const [advertiserName, setAdvertiserName] = React.useState('')
  //   const { currentProject } = useAppSelector((store) => store.project)

  const handleSubmit = async () => {
    if (advertiserId !== '' && advertiserName !== '') {
      setLoading(true)
      const token = await firebase?.auth()?.currentUser?.getIdToken()
      await axios
        .post(
          `${process.env.REACT_APP_FIREBASE_API}/addNewAdvertiser`,
          {
            data: {
              advertiserId: advertiserId,
              advertiserName: advertiserName,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Access-Control-Expose-Headers': 'Content-Disposition',
            },
          },
        )
        .then(() => {
          handleKeywordDialog()
          setAdvertiserId('')
          setAdvertiserName('')
          Toast({
            title: 'Advertiser Created!',
            variant: 'success',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
        .catch((error: any) => {
          handleKeywordDialog()
          setAdvertiserId('')
          setAdvertiserName('')
          console.log(error)
          Toast({
            title: 'Creation Failed!',
            variant: 'error',
            options: { autoClose: 5000 },
          })
          setLoading(false)
        })
    }
  }

  return (
    <>
      <Dialog
        placeholder={undefined}
        open={openKeywordDialog}
        handler={handleKeywordDialog}
        size="xs"
        className="p-4"
      >
        <DialogHeader
          placeholder={undefined}
          className="text-xl font-medium py-2 px-4 flex flex-col items-start space-y-4"
        >
          {/* Add new Keyword */}
          <Typography className="text-2xl font-semibold text-wellBlueGray-900">
            Add New Advertiser
          </Typography>
        </DialogHeader>
        <DialogBody placeholder={undefined}>
          <div className="mb-1 flex flex-col">
            <Typography
              variant="h6"
              className="mb-1 text-sm font-medium text-wellBlueGray-900"
            >
              Advertiser ID
            </Typography>
            <Input
              autoFocus
              type="text"
              size="lg"
              value={advertiserId}
              placeholder="e.g., 1234567890"
              className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
              labelProps={{
                className: 'hidden',
              }}
              style={{ borderRadius: '8px' }}
              onChange={(e) => setAdvertiserId(e.target.value)}
              crossOrigin={undefined}
            />
          </div>
          <div className="mb-1 flex flex-col my-3">
            <Typography
              variant="h6"
              className="mb-1 text-sm font-medium text-wellBlueGray-900"
            >
              Advertiser Name
            </Typography>
            <Input
              autoFocus
              type="text"
              size="lg"
              value={advertiserName}
              placeholder="e.g., XYZ Advertising"
              className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100 w-full"
              labelProps={{
                className: 'hidden',
              }}
              style={{ borderRadius: '8px' }}
              onChange={(e) => setAdvertiserName(e.target.value)}
              crossOrigin={undefined}
            />
          </div>
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          <Button
            variant="text"
            color="black"
            onClick={() => {
              handleKeywordDialog()
              setAdvertiserId('')
              setAdvertiserName('')
            }}
            className="mr-1"
            placeholder={undefined}
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="filled"
            className="!bg-lstnGreen-500"
            placeholder={undefined}
            onClick={handleSubmit}
            loading={loading}
          >
            <span>Save</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}
