import React, { useMemo } from 'react'
import firebase from 'firebase/compat/app'
import Header from './Header'
import Sidebar from './Sidebar'
import { useLocation } from 'react-router-dom'
import { useAppSelector } from '../store/store'
import { KeywordDialogRef } from '../pages/Keywords'
// import { useAppSelector } from '../store/store'
type LayoutProps = {
  children: React.ReactNode
  user: firebase.User
  isEnterprise?: boolean
  childRef: KeywordDialogRef | any
}
const Layout: React.FC<LayoutProps> = ({
  children,
  user,
  isEnterprise,
  childRef,
}) => {
  const location = useLocation()
  const { loading } = useAppSelector((store) => store.project)
  const checkPath = useMemo(() => {
    const currentPath = location && location.pathname
    if (
      currentPath === '/dashboard' ||
      currentPath === '/content-marketing' ||
      currentPath === '/directories-listing' ||
      currentPath === '/integrations' ||
      currentPath === '/project-settings' ||
      currentPath.includes('/keyword/') ||
      currentPath === '/keywords'
      //    &&
      // loading === 'succeeded'
    ) {
      return location.pathname
    } else {
      return
    }
  }, [loading, location])

  return (
    <div className="flex">
      {user && user.uid && checkPath && (
        <div className="h-screen hidden lg:flex w-[22rem] sticky top-0">
          <Sidebar childRef={childRef} />
        </div>
      )}
      <div className="app-container w-full">
        {user && user.uid && location.pathname !== '/create-account' ? (
          <Header
            isEnterprise={isEnterprise}
            userId={user.uid}
            children={undefined}
            childRef={childRef}
          />
        ) : (
          ''
        )}
        {children}
      </div>
    </div>
  )
}

export default Layout
