import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
} from '@material-tailwind/react'
import Typography from '../Typography/Typography'
import Selector from '../SelectInput/Select'

interface IProps {
  open: boolean
  handleOpen: () => void
  handleEdit?: (
    accountId: string,
    memberId: string,
    role: string,
    memberRole: string,
  ) => void
  handleRemove?: (accountId: string, memberId: string, role: string) => void
  handleAdd?: (accountId: string, email: string, role: string) => void
  dialogType: 'add' | 'edit' | 'remove' | 'inviteremove'
  currentAccountId: string
  memberId?: string
  memberRole?: string
  email?: string
  handleInviteRemove?: (removeInviteId: string) => void
  removeInviteId?: string
  addLoading?: boolean
}

export default function MembersDialog({
  open,
  handleOpen,
  handleEdit,
  handleRemove,
  handleAdd,
  dialogType,
  currentAccountId,
  memberId,
  memberRole,
  email,
  handleInviteRemove,
  addLoading,
  removeInviteId,
}: IProps): JSX.Element {
  const { t } = useTranslation()
  const [emailAddress, setEmailAddress] = useState<string>('')
  const [role, setRole] = useState<string>('standard')
  const [, setId] = useState<string>('')

  useEffect(() => {
    if (email) {
      setEmailAddress(email)
    }
    if (memberRole) {
      setRole(memberRole)
    }
    if (memberId) {
      setId(memberId)
    }
  }, [email, memberRole, memberId])

  const roles = [
    { value: 'admin', label: 'Admin' },
    { value: 'standard', label: 'Standard' },
  ]

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmailAddress(event.target.value)
  }

  const handleRoleChange = (value: string | undefined): void => {
    if (value) {
      setRole(value)
    }
  }

  return (
    <Dialog
      open={open}
      handler={handleOpen}
      placeholder={undefined}
      size="sm"
      className="p-4"
    >
      <DialogHeader
        id="alert-dialog-slide-title"
        className="py-2 px-4 flex flex-col items-start"
        placeholder={undefined}
      >
        <Typography className="text-2xl font-semibold text-blue-gray-900 mb-3">
          {dialogType === 'add'
            ? t('accountSettings.addUser')
            : dialogType === 'edit'
              ? t('accountSettings.editUser')
              : dialogType === 'remove'
                ? t('accountSettings.removeUser')
                : dialogType === 'inviteremove'
                  ? t('accountSettings.removeInvite')
                  : null}
        </Typography>
        {dialogType === 'add' || dialogType === 'edit' ? (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('accountSettings.subHeading')}
          </Typography>
        ) : dialogType === 'remove' || dialogType === 'inviteremove' ? (
          <Typography className="text-lg font-normal text-blue-gray-500">
            {t('accountSettings.confirmRemove', { user: email })}
          </Typography>
        ) : null}
      </DialogHeader>
      {(dialogType === 'add' || dialogType === 'edit') && (
        <DialogBody placeholder={undefined} data-cy="data-project-rename-name">
          <>
            <Typography
              variant="h6"
              className="mb-2 text-sm font-medium text-lstnBlueGray-900"
            >
              Email
            </Typography>
            <Input
              autoFocus
              type="email"
              size="lg"
              value={emailAddress}
              placeholder="Email Address"
              className="text-lstnBlueGray-900 !border-blue-gray-200 focus:!border-gray-400  placeholder:text-blue-gray-500 placeholder:opacity-100"
              labelProps={{
                className: 'hidden',
              }}
              disabled={email ? true : false}
              style={{ borderRadius: '8px' }}
              onChange={handleEmail}
              crossOrigin={undefined}
            />
            <Typography
              variant="h6"
              className="mb-2 mt-6 text-sm font-medium text-lstnBlueGray-900"
            >
              {t && t('accountSettings.role')}
            </Typography>
            <Selector
              selectClassName="border border-blue-gray-100 rounded-lg isolate"
              className="w-auto"
              selectedValue={role}
              options={roles}
              onChange={handleRoleChange}
              children={undefined}
            />
          </>
        </DialogBody>
      )}
      <DialogFooter placeholder={undefined} className="gap-0.5">
        <Button
          onClick={handleOpen}
          variant="text"
          className="text-xs font-bold uppercase hover:bg-transparent"
          data-cy="data-project-rename-cancel"
          placeholder={undefined}
        >
          {t('accountSettings.cancel')}
        </Button>
        {dialogType === 'edit' ? (
          <Button
            onClick={() =>
              handleEdit &&
              handleEdit(currentAccountId, memberId!, role, memberRole!)
            }
            className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            disabled={role === memberRole ? true : false}
            data-cy="data-project-rename-submit"
            loading={addLoading}
          >
            {t('accountSettings.save')}
          </Button>
        ) : dialogType === 'add' ? (
          <Button
            onClick={() => {
              handleAdd && handleAdd(currentAccountId, emailAddress, role)
              handleOpen()
              setEmailAddress('')
            }}
            className="bg-lstnGreen-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            disabled={!emailAddress}
            data-cy="data-project-rename-submit"
            loading={addLoading}
          >
            {t('accountSettings.save')}
          </Button>
        ) : dialogType === 'remove' ? (
          <Button
            onClick={() =>
              handleRemove && handleRemove(currentAccountId, memberId!, role)
            }
            className="bg-red-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            data-cy="data-project-rename-submit"
            loading={addLoading}
          >
            {t('accountSettings.removeUser')}
          </Button>
        ) : dialogType === 'inviteremove' ? (
          <Button
            onClick={() =>
              handleInviteRemove && handleInviteRemove(removeInviteId!)
            }
            className="bg-red-500 text-white text-xs font-bold uppercase rounded-lg"
            placeholder={undefined}
            variant="filled"
            data-cy="data-project-rename-submit"
            loading={addLoading}
          >
            {t('accountSettings.removeInvite')}
          </Button>
        ) : null}
      </DialogFooter>
    </Dialog>
  )
}
