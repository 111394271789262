import React, { useEffect, useState } from 'react'
import {
  Drawer,
  // Button,
  Typography,
  IconButton,
  ThemeProvider,
} from '@material-tailwind/react'
import { useAppSelector } from '../../store/store'
import { getAdById, Iincident } from '../../store/features/keywordSlice'
import { fetchAdvertiserById } from '../../store/features/adSlice'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { Status } from '../../enums'
import { AdInfo } from '../../interfaces'
import { useNavigate, useParams } from 'react-router-dom'

const theme = {
  drawer: {
    defaultProps: {
      overlay: true,
      placement: 'right',
      overlayProps: undefined,
      className: '',
      dismiss: undefined,
      onClose: undefined,
      transition: {
        type: 'tween',
        duration: 0.3,
      },
    },
    styles: {
      base: {
        drawer: {
          position: 'fixed',
          zIndex: 'z-[9999]',
          pointerEvents: 'pointer-events-auto',
          backgroundColor: 'bg-white',
          boxSizing: 'box-border',
          width: 'w-full',
          boxShadow: 'shadow-2xl shadow-blue-gray-900/10',
        },
        overlay: {
          position: 'fixed',
          inset: 'inset-0',
          width: 'w-full',
          height: 'h-full',
          pointerEvents: 'pointer-events-auto',
          zIndex: 'z-[9995]',
          backgroundColor: 'bg-black',
          backgroundOpacity: 'bg-opacity-60',
          backdropBlur: 'backdrop-blur-sm',
        },
      },
    },
  },
}

interface IAdvertiser {
  id: string
  legal_name: string
  google_ads_id: string
}
export function DrawerRight() {
  const [currentIncident, setCurrentIncident] = useState<Iincident | null>(null)
  const [advertiserName, setAdvertiserName] = useState<string | null>(null)
  const { currentKeywordIncidents } = useAppSelector((store) => store.keyword)
  const [violationAd, setViolationAd] = useState<AdInfo | null>(null)
  // console.log(currentIncident, 'currentIncident')
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentKeywordIncidents) {
      const currentIncident = currentKeywordIncidents.find(
        (incident) => incident.docId == id,
      )
      if (currentIncident) {
        setCurrentIncident(currentIncident)
      }
    }
  }, [currentKeywordIncidents, currentKeywordIncidents.length, id])

  useEffect(() => {
    const getAdvertiserName = async () => {
      if (currentIncident) {
        const advertiser = (await fetchAdvertiserById(
          currentIncident.advertiser_id,
        )) as IAdvertiser
        const ad = await getAdById(currentIncident?.ad_id!)
        if (advertiser) {
          setAdvertiserName(advertiser.legal_name)
        }
        if (ad) {
          setViolationAd(ad)
        }
      }
    }
    getAdvertiserName()
  }, [currentIncident])

  const handleCloseDrawer = () => {
    navigate(-1)
  }

  return (
    <ThemeProvider value={theme}>
      <React.Fragment>
        {/* <Button placeholder={undefined} onClick={openDrawer}>Open Drawer</Button> */}
        <Drawer
          placeholder={undefined}
          placement="right"
          open={id ? true : false}
          onClose={handleCloseDrawer}
          className={`p-4 ${id ? 'lg:!max-w-md' : ''}`}
        >
          <div className="mb-6 flex items-center justify-between drawer_class">
            <Typography variant="h5" color="blue-gray" placeholder={undefined}>
              Incident #{currentIncident?.docId}
            </Typography>
            <IconButton
              placeholder={undefined}
              variant="text"
              color="blue-gray"
              onClick={handleCloseDrawer}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </IconButton>
          </div>
          <div className="flex flex-col gap-2 text-blue-gray-500">
            <Typography
              placeholder={undefined}
              className="mb-4 pr-4 font-normal gap-2"
            >
              <span className="font-bold mr-1">Status:</span>
              <span>
                {' '}
                {currentIncident?.status.includes(Status.USER_IDENTIFIED) ? (
                  <FiberManualRecordIcon
                    className="w-4 h-4 text-red-500"
                    style={{ width: '20px', height: '20px' }}
                  />
                ) : currentIncident?.status.includes(Status.USER_RESOLVED) ? (
                  <CheckCircleIcon
                    className=" text-green-500"
                    style={{ width: '18px', height: '18px' }}
                  />
                ) : currentIncident?.status.includes(Status.USER_REPORTED) ? (
                  <CheckCircleOutlineIcon
                    className="w-4 h-4 text-green-500"
                    style={{ width: '18px', height: '18px' }}
                  />
                ) : null}
              </span>
              <span>{currentIncident?.status}</span>
            </Typography>

            <div className="flex flex-col text-blue-gray-500">
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">First identified on:</span>
                <span>
                  {currentIncident &&
                    currentIncident?.createdAt?.toDate().toLocaleDateString()}
                </span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Reported on:</span>
                <span> -- </span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Resolved on:</span>
                <span> n/a</span>
              </Typography>
            </div>

            <div className="flex flex-col text-blue-gray-500 mt-2">
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Advertiser: </span>
                <span>{advertiserName ? advertiserName : '--'}</span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Advertiser website: </span>
                <span>{currentIncident?.violationUrl}</span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Countries: </span>
                <span>{violationAd?.ad_countries?.join(', ')}</span>
              </Typography>
            </div>

            <div className="flex flex-col text-blue-gray-500 mt-2">
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Text: </span>
                <span>{currentIncident?.ad_title}</span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Subtitle: </span>
                <span> {currentIncident?.ad_subtitle}</span>
              </Typography>
              <Typography placeholder={undefined} className="pr-4 font-normal">
                <span className="font-bold mr-2">Target url: </span>
                <span>--</span>
              </Typography>
            </div>
          </div>
          {/* <div className="flex justify-start items-center gap-4 my-8">
            <Button variant="outlined" placeholder={undefined}>
              DOWNLOAD INFO
            </Button>
            <Button
              variant="filled"
              className="bg-blue-500 text-white"
              placeholder={undefined}
            >
              REPORT TO GOOGLE
            </Button>
          </div> */}
        </Drawer>
      </React.Fragment>
    </ThemeProvider>
  )
}
