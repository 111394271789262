import firebase from 'firebase/compat/app'

export const parsingWebsite = async (
  websiteUrl: string,
  projectId: string,
): Promise<any> => {
  const token = await firebase.auth().currentUser?.getIdToken()

  const response = await fetch(
    `${process.env.REACT_APP_FIREBASE_API}/crawl-project-website`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ websiteUrl: websiteUrl, projectId: projectId }),
    },
  )
  if (response.status === 200) {
    console.log('website parsed successfully')
  } else {
    return await response.json()
  }
}
